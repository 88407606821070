.form-des-1{
    /* text-align: center !important; */
    color: #000 !important;

font-family: Poppins;
/* font-size: 26.335px; */
font-style: normal;
font-weight: 500;
line-height: normal;
height: 30vh;

  /* margin-top: 15px; */
}
.project-text{
    color: #000;
font-family: Poppins;
/* font-size: 43.099px; */
font-style: normal;
font-weight: 700;
line-height: normal;
}
.project-text-1{
    color: #000;
font-family: Poppins;
/* font-size: 43.099px; */
font-style: normal;
font-weight: 400;
line-height: normal;
}