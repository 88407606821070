/* Navbar */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.herd-nav{
    background-image: url('../assets/bluebg.png'); /* Replace with actual image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh; /* Ensures it covers full viewport */
    color: white; /* Adjust text color */
  }
  /* */
  .navbar {
    background: transparent !important; /* Make sure navbar is transparent */
    position: absolute; /* Ensures it stays on top of background */
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  @media (max-width:768px){
    .herd-nav{
      background-image:url(../assets/Vector1.png) ; /* Replace with actual image path */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      min-height: 100vh; /* Ensures it covers full viewport */
      color: white; /* Adjust text color */
    }
  }
  .homepic{
    width:70%;
    /* height: auto; */
  }
  .homepics{
    width: 100%;
    margin-left: 25px;
  }
.herd-nav .navbar {
    /* background-color: #FFFF01; */
    border-bottom: 1px solid rgba(255, 255, 255, .3) !important; 
    padding: 15px 55px;
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: background-color 0.3s ease; */
}

.herd-nav .nav-link {    
    font-family: "Poppins", serif;
    color: #000000;
    font-weight: 600 !important;
    padding-right: 30px !important;
    font-size: 15px;
    padding-left: 0px !important;
}
/* .herd-nav .nav-link:last-child {
    padding-right: 0px !important;
} */
.herd-nav .nav-link active {
    border-bottom: 2px solid white;


}
/* .herd-nav .top-bar-sections{ */
    /* width: 100%;
    display: flex !important;
    justify-content: space-between !important; */
/* } */
.herd-nav .get-start-btn {
    color: black !important;
    background-color: #FFFF01;
    font-weight: 500;
    border-radius: 8px;
    padding: 3px 17px;
    font-size: 14px;
    font-family: "Poppins", serif;
    line-height: 20px;
    margin-top: 6px;
}
.herd-nav .get-start-btn:hover {
    background-color: #FFFF01;    
    box-shadow: 0 5px 8px rgba(0, 0, 0, .2);
    transform: translateY(-3px);
    opacity: .95;
    color: black;    
}
.herd-nav .navbar-toggler-icon {
    color: #fff !important;
    border: none !important;
}
.herd-nav .phone-mail-div{
    text-align: start;
    margin-bottom: 0px;
    font-weight: 600 !important;
}
.herd-nav .phone-mail-color {
    font-size: 14px;
    color: rgba(255, 255, 255, .5) !important;
}

@media (max-width: 768px){
    .herd-nav .main-brand-contact{
        flex-direction: column !important;
    }

} 

 html,body{
  overflow-x: hidden;
 }
@media (max-width: 576px){
    .herd-nav .contact-info{
        display: none !important;
    }
    .autoplay-tab-carousel {
        display: flex;
    flex-direction: column;
      }
      .autoplay-tab-carousel .tab-content {
        width: 100% !important;
        padding: 10px;
      }
      .autoplay-tab-carousel h1{
        font-family: "Poppins", serif;
        font-size: 17px !important;
        font-weight: 600;
        /* line-height: 39px; */
        text-align: center !important;
        /* color: #020F6A; */
        padding: 0px 0px !important ;
        padding-bottom: 20px;
      }
    
    .product-title{
      margin-right: 80px !important;
    }
    .herd-nav .navbar{
      padding: 0px !important;
    }
    .herd-nav{
      padding: 10px;
    }
    .AboutSections .about-section-2-carousel-container .slide-content{
      font-size: 14px !important;
    }
} 
.SectionFour {
    font-family: "Poppins", serif;
 
    padding: 50px 0px;
}
.sectionfour-para-2 {
    padding: 0px 60px;
font-size: 18px;
font-weight: 400;
line-height: 27px;
text-align: center;
}
.section-4-main-heading{
font-size: 28px;
font-weight: 700;

text-align: center;
color: rgba(0, 0, 0, 1);


}
.steps-bg{
  /* background: #E0FFF1 !important; */
  /* padding: 5px !important; */
}

/* Carousel */
.autoplay-tab-carousel {
    display: flex;
    /* background-color: #FFFF01; */
    color: #000;
    font-family: "Poppins", serif !important;
  }
  
  .autoplay-tab-carousel .tabs {
    width: 20%;
    background-color: #ffffff;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    color: #000000;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
  }
  
  .autoplay-tab-carousel .tabs button {
    color: #000000;
    font-weight: 500;
    padding: 15px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
  }
  
  .autoplay-tab-carousel .tabs button:hover,
  .tabs button.active {
    background: rgba(195, 234, 216, 1);

    color: #000 !important;
    font-weight: bold;
  }
  
  .autoplay-tab-carousel .tab-content {
    width: 80%;
    padding: 20px;
  }
  
  .autoplay-tab-carousel .content {
    text-align: center;
  }
  
  .autoplay-tab-carousel .carousel-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 20px 0;
  }
  
  .autoplay-tab-carousel h1{
    font-family: "Poppins", serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    text-align: left;
    /* color: #020F6A;     */
    padding: 0px 60px;
    padding-bottom: 20px;
  }

/* Teach Carousel */
.tech-carousel {
    background-color: #CCCCCC;
}
.tech-carousel .carousel-container {

    padding: 10px 20px;
}  
.tech-carousel .carousel-item {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: auto; 
}
.tech-carousel .carousel-item img {
    max-width: 80px;
    margin: 0 auto;
}
.tech-carousel h2 {
    font-family: "Poppins", serif;
    color: #FFD000;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    margin: 0;
}  
.sectiontwo-para-1 {    
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    }

/* SectionWhite */
.SectionWhite {
    background-color: #FFFFFF;
    height: 60px;
}

.kevell-text{
    font-size: 53px;
    font-family: "Poppins", serif;
    color: black;
    /* display: flex; */
    text-align: right !important;
    font-weight: 700;
    letter-spacing: 5px;

    
}


.gradient-text {
color: gray;
  }
.sectionone-para-1 {    
    font-size: 53px;
    font-weight: 700;
    /* line-height: 60px; */
    color: #CCCCCC;
    letter-spacing: 8px;
    text-align: right !important;
}
.sectionone-para-1 span{   
    color: #000000;
    letter-spacing: 8px;
}
.sectionone-para-2 {
    color: #B1B1B1;
    text-align: right !important;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    
    letter-spacing: 5px;
}
.read-more-btn {
    color: #000 !important;

    font-family: Poppins;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    border-radius: 15px !important;
    /* background: rgba(255, 248, 109, 1) ; */

}
.step-bg{
  background-image: url(../assets/Wireframe.png); /* Replace with actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh; /* Ensures it covers full viewport */


}
.step-1{
  padding-top: 340px;

 
}
.step-2{
  padding-top: 320px;
  /* padding-left: 30px; */
  /* position */
  position: relative;
  left: 36px;
}
.step-3{
  padding-top: 240px;
  position: relative;
  left: 15px;
}
.step-4{
  padding-top: 100px;
  position: relative;
  right: 10px;
  
}
.step-5{
  /* padding-bottom: 180px !important; */
  position: relative;
  bottom: 120px !important;
  right: 35px;
  /* top: 0px; */
}
.step-6{
  /* padding-bottom: 180px !important; */
  position: relative;
  /* bottom: 120px !important; */
  right: 40px;
  /* top: 0px; */
}
.step-7{
  /* padding-bottom: 180px !important; */
  position: relative;
  /* bottom: 120px !important; */
  right: 60px;
  top: 50px;
  /* top: 0px; */
}
.charpic{
  position: absolute;
  /* max-width: 500px !important; */
  width: 70%;
  right: 170px;
  top: 35px;
  /* bottom: 20px; */
}
.flag-container {
 
  /* height: 200px; */
}

.waving-flag {

  height: auto;
  animation: waveFlag 3s infinite ease-in-out;
  transform-origin: left center;

}

@keyframes waveFlag {
  0% { transform: perspective(500px) rotateY(0deg); }
  25% { transform: perspective(500px) rotateY(10deg); }
  50% { transform: perspective(500px) rotateY(0deg); }
  75% { transform: perspective(500px) rotateY(-10deg); }
  100% { transform: perspective(500px) rotateY(0deg); }
}

.ul-color li{
color: black;
font-family: Poppins;
font-style: normal;
font-weight: 600;
line-height: normal;
/* font-size: 12px; */

}
.step-text{
  color: #000;

font-family: Poppins;
/* font-size: 32px; */
font-style: normal;
font-weight: 600;
line-height: normal;
}

.homepic-center{
    justify-content: start ;
}
.read-center{
    justify-content: end ;
}
@media (min-width:768px) and (max-width:991px)
{
    .kevell-text{
        font-size: 20px;
        font-family: "Poppins", serif;
        color: black;
        /* display: flex; */
        text-align: right !important;
        font-weight: 700;
        letter-spacing: 3px;
    
        
    }
    .sectionone-para-1 {    
        font-size:20px;
        font-weight: 700;
        /* line-height: 60px; */
        color: #CCCCCC;
        letter-spacing: 5px;
        text-align: right !important;
    }
    .sectionone-para-2 {
        color: #B1B1B1;
        text-align: right !important;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        
        letter-spacing: 1px;
    }
}
@media (min-width:992px) and (max-width:1399px)
{
    .section-five .brand-box {
      
        border-radius: 5px;
        width: 100% !important; /* Fixed width */
       
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: space-between; */
        /* align-items: center; */
        padding: 15px;
        margin: 10px auto; /* Center align in its column */
        transition: transform 0.3s ease;
        position: relative;
      }
    .kevell-text{
        font-size: 37px;
        font-family: "Poppins", serif;
        color: black;
        /* display: flex; */
        text-align: right !important;
        font-weight: 700;
        letter-spacing: 4px;
    
        
    }
    .sectionone-para-1 {    
        font-size:40px;
        font-weight: 700;
        /* line-height: 60px; */
        color: #CCCCCC;
        letter-spacing: 8px;
        text-align: right !important;
    }
    .sectionone-para-2 {
        color: #B1B1B1;
        text-align: right !important;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        
        letter-spacing: 1px;
    }
}
@media (max-width:767px) {
    .homepic-center{
        justify-content: center ;
    }
    .read-center{
        justify-content: center ;
    }
    .kevell-text{
        font-size: 25px;
        font-family: "Poppins", serif;
        color: black;
        /* display: flex; */
        text-align: center !important;
        font-weight: 700;
        letter-spacing: 1px;
    
        
    }
    
    .gradient-text {
    color: gray;
      }
    .sectionone-para-1 {    
        font-size: 25px;
        font-weight: 700;
        /* line-height: 60px; */
        color: #CCCCCC;
        letter-spacing: 1px;
        text-align: center !important;
    }
    .sectionone-para-1 span{   
        color: #000000;
        letter-spacing: 1px;
    }
    .sectionone-para-2 {
        color: #B1B1B1;
        text-align: center !important;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
     
        letter-spacing: 1px;
    }
    .read-more-btn {
        color: #000 !important;
    
        font-family: Poppins;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700 !important;
        border-radius: 15px !important;
        background: rgba(255, 248, 109, 1) !important;
    
    }
    
}
.read-more-btn:hover {    
    color: white;
}
.wel-text{
    color: #000;

font-family: Poppins;
/* font-size: 32px; */
font-style: normal;
font-weight: 600;
line-height: normal;
}
.wel-para{
    color: #626262;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;

}
.backbg-welcome {
    border-radius: 50px 50px 0px 50px;
    background: #C5EBD9;
}
.section-five {
   
    font-family: "Poppins", serif !important;
  }

  .section-five .brand-box {
    border: 1px solid #407BFF;
    border-radius: 10px;
    width: 300px; /* Fixed width */
    height: 320px; /* Fixed height */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    /* align-items: center; */
    padding: 15px;
    margin: 10px auto; /* Center align in its column */
    transition: transform 0.3s ease;
    position: relative;
  }
  
  .section-five .brand-box img {
    max-width: 100%;
    max-height: 200px; /* Adjust maximum height for images */
    object-fit: contain;
  }
  
  .section-five .brand-box p {
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
    flex-grow: 1; /* Ensure the paragraph takes remaining space */
  }
  
  .section-five .brand-box:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  .section-five .section-5-h1 {
    font-size: 27px;
    color: black;
    padding-bottom: 5px;
  }
  .section-five .section-5-h2 {
    font-size:28px;
    color: black;
    padding-bottom: 5px;
  }
  .section-five .section-5-h3 {
    font-size: 24px;
    color: #407BFF;
    line-height: 1.6;
  }  
  .section-five .section-5-content {
    font-size: 19px;
    color: #b3b3b3;
  }
  .section-five .section-5-h1, .section-5-h2, .section-5-h3, .section-5-content {
    text-align: left;
  }
  .section-five .section-5-main-content {
    padding-top: 60px;
  }
  .section-five .section-5-read-more-btn {
    color: black;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-weight: 700;
  }
  
  @media (max-width: 375px){
    .section-five .brand-box {
      width: 280px;
  }
  }
.f-20{font-size: 20px !important;}
.f-18{font-size: 18px !important;}
.f-16{font-size: 16px !important;}
.f-14{font-size: 14px !important;}
.f-12{font-size: 12px !important;}
/* .co-blue{color: #497DFF !important;} */
.co-red{color: #FF0000 !important;}
.co-black{color: #000000 !important;}
.co-grey{color: rgba(85, 81, 81, 1) !important;}
/* .bg-yellow{background-color: #FFFF01 !important;} */


.activetext{
  -webkit-text-fill-color:rgba(78, 92, 252, 1) !important;
}
.journey-images {
  position: relative;
  width: 100% !important;
 
  height: 600px; 
  overflow: hidden; 
}

.journey-images img {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: slide 18s infinite; 
}

.journey-slide:nth-child(1) {
  animation-delay: 0s;
}

.journey-slide:nth-child(2) {
  animation-delay: 3s;
}

.journey-slide:nth-child(3) {
  animation-delay: 6s;
}

.journey-slide:nth-child(4) {
  animation-delay: 9s;
}

.journey-slide:nth-child(5) {
  animation-delay: 12s;
}

.journey-slide:nth-child(6) {
  animation-delay: 15s;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 0;
    transform: scale(1.1); 
  }
  100% {
    opacity: 0;
    transform: scale(1.1); 
  }
}



