.world-text{
    font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
background: linear-gradient(90deg, #FFFF01 0%, #1C1C00 20.67%, #FFFF01 72.67%, #0A0A00 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.world-para{
    color: #000;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.web-text-1{
    color: #000;

/* text-align: center; */
font-family: Poppins;
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.kevell-box{
    border-radius: 13px;
background: #FFF;
}
.kevell-box-1{
    border-radius: 20px;
background: #FFF86D;

}
.white-c{
    color: white !important;
}
@media (min-width:768px) and (max-width:1200px) {
    .kevell-box-1{
        flex-direction: column;
    }
    
}