.footer-main-div{
    font-family: "Poppins", serif;
}
.footer {
    padding: 60px 60px 30px 60px;
}
.footer .footer-company-name {    
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 0px;
    padding-left: 20px;
}
.footer .footer-content {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32.4px;
    text-align: center;
}
.footer .footer-useful-links {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.footer .footer-links {
    padding: 0px 10px;
    color: #444E9B;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}
.footer .footer-inside-list ul {
    display: flex;      
    justify-content: center;
    align-items: center;   
    flex-wrap: wrap;  
    gap: 5px;    
}
.footer ul {
    list-style: none; 
    padding: 0;       
    margin: 0;        
}
.footer .footer-inside-list {
   padding-top: 25px;
}

.footer-main-div .footer-bottom-div {
    background: rgba(199, 236, 219, 1);
    text-align: center;
    color: #000000;
    padding: 20px 0px;
}
.footer-main-div .footer-bottom-div-para-1, .footer-bottom-div-para-2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0px;
    padding-bottom: 10px;
}
/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .footer {
        padding: 20px;
    }
    .footer .footer-company-name {
        font-size: 16px;
    }
    .footer .footer-content {
        font-size: 14px;
    }
    .footer .footer-useful-links,
    .footer .footer-links {
        font-size: 14px;
    }
    .footer-main-div .footer-bottom-div-para-1,
    .footer-bottom-div-para-2 {
        font-size: 14px;
    }
  
}

@media (max-width: 576px) {
    /* .footer .footer-inside-list ul {
        flex-direction: column;
        text-align: center;
    } */

    .autoplay-tab-carousel .tabs{
      width: auto !important;
    }
    .sectionfour-para-2{
      padding: 0px !important;
    }
    .vr-section1, .vr-section2{
      position: relative !important;
    }
    .got-details-page{
      position: static !important;
      top: 0;
      padding-bottom: 20px;
    }
}
