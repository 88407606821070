.service-text{
    color: #000;
font-family: Poppins;
/* font-size: 50.11px; */
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 3.007px;
}
.devops-text{
    color: #000;

font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.service-bg{
    background: linear-gradient(180deg, #FFF 0%, #E0FFF1 21.5%, #E7FFF4 78.5%, #FFF 100%);

}
.devops-para{
    color: #717171;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.learn-more{
    color: #000;

font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration: underline;
}
.course-box{
    background: #FFF;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .2);
    height: 360px !important;
}
@media (min-width:993px) and (max-width:1199px) {
    .course-box{
        background: #FFF;
        box-shadow: 0 5px 8px rgba(0, 0, 0, .2);
        height:430px !important;
    }  
    
}