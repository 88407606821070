.kevell-text-2{
    /* font-size: 53px; */
    font-family: "Poppins", serif;
    color: black;
    /* display: flex; */
    text-align: right !important;
    font-weight: 700;
    letter-spacing: 1px;
  
    
  }
  @media (max-width:767px) {
    .kevell-text-2{
        /* font-size: 53px; */
        font-family: "Poppins", serif;
        color: black;
        /* display: flex; */
        text-align: center !important;
        font-weight: 700;
        letter-spacing: 5px;
      
        
      }
  }
  .work-text{
    color: black;
  
    font-weight: 400;
  }
  .homepics-1{
    max-width: 300px !important;
    width: 100%;
  }
  .join-btn{
    padding: 5px 40px !important;
  }
  .wel-text-1{
    color: #616161;

font-family: Poppins;
/* font-size: 24px; */
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.2px;
  }
  .your-text{
    color: #000;

text-align: center;
font-family: Poppins;
/* font-size: 40px; */
font-style: normal;
font-weight: 600;
line-height: 150.075%
  }
  .form-label{
    color: #797979;

text-align: center;
font-family: Poppins;
/* font-size: 30px; */
font-style: normal;
font-weight: 500;
line-height: 150.075%; 
  }
.red-icon{
    color: #F00 !important;
font-family: Poppins;
/* font-size: 30px; */
font-style: normal;
font-weight: 500 !important;
line-height: 150.075%;
  }
 
  .form-control::placeholder {
    color: #C5C5C5 !important;
    /* text-align: center !important; */
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150.075% !important;
    opacity: 1 !important;
}

  .form-control{
padding: 20px 10px !important;
/* border-radius: 20px !important; */
  }
.form-des{
    text-align: center !important;
  /* margin-top: 15px; */
}
.form-position{
    padding-top: 19% !important;
}
.form-bg{
    border-radius: 0px 60px 60px 60px;
background: #C5EBD9;

}
.form-con{
  border-radius: 20px !important;
}
.submit-btn{
    border-radius: 18px  !important;
background: #FFF976  !important;
color: #000  !important;
padding: 10px 40px !important;
text-align: center  !important;
font-family: Poppins  !important;
/* font-size: 30px; */
font-style: normal  !important;
font-weight: 500  !important;
line-height: 150.075%  !important;
}
.form-control[readonly] {
    color: #C5C5C5 !important;  /* Light gray color */
    /* font-style: italic !important; */

    font-family: 'Poppins', sans-serif !important;
    cursor: default !important;

    font-weight: 500 !important;
    line-height: 150.075% !important;
}
.about-img-1 {
    position: absolute;
    top: -23%; /* Move more upwards */
    right: 93%;
    transform: translate(50%, 0%); /* Remove vertical translation */
    width: 300px;
    height: auto;
}
.upload-field{
  border-top-left-radius: 20px !important;
  border-bottom-left-radius:20px  !important;
}
.upload-pic{
  border-top-right-radius: 20px !important;
  border-bottom-right-radius:20px  !important;
}
    /* Responsive Styling */
    @media (max-width: 1399px) {
        .about-img-1 {
          width: 250px; /* Reduce image size for tablets */
        }
      }
      @media (max-width:991px) {
        .about-img-1 {
          width: 250px; 
        
          right: 89.5% !important;
          /* top: -23%; */
        /* Reduce image size for tablets */
        }
        
        
 
 
      }
      @media (max-width:1199px) {
        .about-img-1 {
          width: 240px; 
        
          right: 92%;
          /* top: -23%; */
        /* Reduce image size for tablets */
        }
        
        
 
 
      }
     
      
  /* Responsive Styling */
  @media (min-width: 481px) and (max-width: 767px) {
    .about-img-1 {
      width: 165px;
      top: -14%;
      right: 90% !important;
    }
    .form-control{
      padding: 10px 10px !important;
      /* border-radius: 20px !important; */
      
        }
  }
  
  @media  (min-width: 425px) and (max-width:480px) {
    .about-img-1 {
      width: 158px;
      right: 89% !important;
      top: -10%;
 /* Reduce image size for mobile */
    }
    .form-control{
      padding: 10px 10px !important;
      /* border-radius: 20px !important; */
        }
  }
  @media (min-width: 320px) and (max-width:425px) {
    .about-img-1 {
      width: 128px;
      top: -8%;
      right: 89% !important;
 /* Reduce image size for mobile */
    }
    .form-control{
      padding: 10px 10px !important;
      /* border-radius: 20px !important; */
        }
  }
  