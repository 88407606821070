.homepic1{
    width:87%;
}
.about-head-1{
    color: #000;

    font-family: Poppins;
    /* font-size: 20px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.about-para-1{
    color: #717171;

    font-family: Poppins;
    /* font-size: 13px; */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.about-bg{
    background: #C5EBD9;
}
.join-text{
    color: #000;

text-align: center;
font-family: Poppins;
/* font-size: 40px; */
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.2px
}
.man-name{
    color: #000;

font-family: Poppins;
font-size: 17.013px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.51px;
}

.men-pic{
    width: 100%;
    max-width: 175px !important;
}
.pic-1{
    width: 100%;
    max-width: 175px !important;
}
.border-box{
  border: 1px solid #9C9C9C;
  background: #DCFFEE;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
min-height: 350px !important;
text-align: center;
/* height: 100%; */
}
.border-box-1{
  border: 1px solid #9C9C9C;
  background: #DCFFEE;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
min-height: 300px !important;
max-width: 270px !important;
margin: auto;
width: 100%;
/* height: 100%; */
}
.about-yellow-bg{
border-radius: 99.569px 99.569px 199.139px 99.569px;
background: #FFF976;
display: flex;
justify-content: center;
align-items: center !important;
padding-top: 30% !important;
}
.company-text{
    color: #000;
text-align: center;
font-family: Poppins;
/* font-size: 42.414px; */
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.272px;
}
.yellow-height{
    /* height: 100vh; */
    padding-top: 15%;
 
}
.about-img {
    position: absolute;
    top: 0;
    left: 90%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: auto;
  }
    /* Responsive Styling */
    @media (max-width: 1399px) {
        .about-img {
          width: 250px; 
        }
      }
      @media (max-width:991px) {
        .about-img {
          width: 200px; 
          left: 86%;
        }
        
        
 
 
      }
      @media (max-width: 1199px) {
        .about-img {
          width: 200px; 
        }
      }
      @media (min-width:768px) and (max-width:992px) {
        .border-box{
   
        max-width: 270px !important;
        margin: auto;
          
          }
      }
    
        
      
  /* Responsive Styling */
 
  @media (max-width: 767px) {
    .about-img {
      width: 120px;
    }
    .border-box{
 
  min-height: 350px !important;
  width: 273px;
  margin: auto;
  text-align: center;
  
  }
  }
  
  @media (max-width: 480px) {
    .about-img {
      width: 100px;
      left: 80%; /* Reduce image size for mobile */
    }
  }
 