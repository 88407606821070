.www{
    color: #7B7B7B;
font-family: Poppins;
/* font-size: 20px; */
font-style: normal;
font-weight: 500;
line-height: 150.06%;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
.web-para{
    color: #737373;
font-family: Poppins;
font-size: 15.753px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.web-text{
    color: #6F6F6F;
font-family: Poppins;
font-size: 25.57px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
@media (max-width:767px) {
    .web-position{
        flex-direction: column;
        
    }
}